<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant type="search" v-model="searchParam.plantCd" />
        </div>
      </template>
    </c-search-box>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <c-table
          ref="table"
          title="공사현장별 무재해현황"
          tableId="table"
          :columns="grid.columns"
          :data="grid.data"
          @linkClick="linkClick"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn v-if="editable" label="신규" icon="add" @btnClicked="openPop" />
              <c-btn label="검색" icon="search" @btnClicked="getList" />
            </q-btn-group>
          </template>
        </c-table>
        <c-dialog :param="popupOptions"></c-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'project-manage',
  data() {
    return {
      searchParam: {
        plantCd: null,
      },
      popupOptions: {
        isFull: true,
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      grid: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: '공사현장 명',
            align: 'center',
            sortable: true,
            type: 'link',
          },
          {
            name: 'startDt',
            field: 'startDt',
            label: '무재해 시작일',
            style: 'width:300px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'dateCnt',
            field: 'dateCnt',
            label: '무재해일수',
            align: 'right',
            type: 'cost',
            style: 'width:200px',
            sortable: true,
          },
          {
            name: 'targetDt',
            field: 'targetDt',
            label: '무재해 목표달성 예정일',
            align: 'center',
            style: 'width:300px',
            sortable: true,
          },
          {
            name: 'endFlag',
            field: 'endFlag',
            label: '종료여부',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
        ],
        data: [],
      },
      editable: true,
      headerUrl: '',
      listUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.listUrl = selectConfig.pjm.uninjury.list.url;
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    linkClick(row) {
      this.openPop(row);
    },
    openPop(row) {
      this.popupOptions.title = '무재해 현황 상세';
      this.popupOptions.param = {
        pjmProjectUninjuryId: row ? row.pjmProjectUninjuryId : '',
      };
      this.popupOptions.target = () => import(`${"./projectUninjuryDetail.vue"}`);
      this.popupOptions.visible = true;
      this.popupOptions.isFull = false;
      this.popupOptions.width = '60%';
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
  }
};
</script>
